<template>
  <div class="pa-3">
    <v-card>
      <v-card-title>
        <v-tabs v-model="tabs" class="my-tabs">
          <v-tab> Journaux </v-tab>
          <v-tab> Remises </v-tab>
        </v-tabs>
      </v-card-title>

      <v-card-text v-if="tabs == 0">
        <v-container>
          <v-row dense>
            <v-col cols="12" sm="1" md="1">
              <v-select
                :items="exercices"
                v-model="exercice"
                :label="'Exercice'"
                @change="periodes_load"
                dense
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-select
                :items="periodes"
                item-value="periode"
                v-model="mois_id"
                :label="'Mois'"
                @change="periode_change"
                :loading="periode_load"
                dense
              >
                <template v-slot:selection="{ item }">
                  <!-- HTML that describe how select should render selected items -->
                  {{ item.label }}
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title v-html="item.label"></v-list-item-title>
                    <v-list-item-subtitle
                      v-html="
                        'Etat : ' + (item.cloture == 1 ? 'Cloturé' : 'Ouvert')
                      "
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-col>
            <v-col
              cols="12"
              sm="2"
              md="1"
              v-if="
                $store.state.options[0].transf_cmpt == 1 &&
                mois_id &&
                ($store.state.auth.includes('01101') || $store.state.isadmin)
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ma-4"
                    @click="lock_month"
                    :disabled="
                      mois_id == null ||
                      (periode.cloture == 1 && periode.g50_date != null)
                    "
                    v-bind="attrs"
                    v-on="on"
                    small
                    ><v-icon left>
                      {{ periode.cloture == 1 ? "mdi-lock-open" : "mdi-lock" }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{
                  periode.cloture == 1 ? "Décloturer Mois" : "Cloturer Mois"
                }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-chip>Vente</v-chip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mx-6"
                    @click="export_journal('vente')"
                    :disabled="mois_id == null"
                    v-bind="attrs"
                    v-on="on"
                    small
                    ><v-icon left> mdi-download </v-icon>
                  </v-btn>
                </template>
                <span>Exporter Journal</span>
              </v-tooltip>
              <v-divider class="mt-3"></v-divider>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-chip>Achat</v-chip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mx-6"
                    @click="export_journal('achat')"
                    :disabled="mois_id == null"
                    v-bind="attrs"
                    v-on="on"
                    small
                    ><v-icon left> mdi-download </v-icon>
                  </v-btn>
                </template>
                <span>Exporter Journal</span>
              </v-tooltip>
              <v-divider class="mt-3"></v-divider>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-chip>Banque</v-chip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mx-6"
                    @click="export_journal('banque')"
                    :disabled="mois_id == null"
                    v-bind="attrs"
                    v-on="on"
                    small
                    ><v-icon left> mdi-download </v-icon>
                  </v-btn>
                </template>
                <span>Exporter Journal</span>
              </v-tooltip>
              <v-divider class="mt-3"></v-divider>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-text v-if="tabs == 1">
        <v-row>
          <v-col cols="12" sm="5" md="5" v-if="!new_remise">
            <listitemspage
              :selitem="selitem"
              :qSelect="Qselect"
              :qDelete="qDelete"
              :qSelectall="Qselectall"
              :PageResponse="'remises'"
              :AllResponse="'allremises'"
              :title="'Remises'"
              :headers="remiseheaders"
              :field="'CREATED_AT'"
              :filename="'Remises'"
              :sheetname="'Remises'"
              @open="RemiseOpen"
              @rowselect="RemiseChange"
              :showedit="false"
              :Add="true"
              :del="true"
              :del_disable="cheques_list.length > 0"
              :list_options="false"
              :key="klist2"
            >
            </listitemspage>
          </v-col>
          <v-col cols="12" sd="7" md="7">
            <remiseform
              :item="remise"
              :agences="agences"
              :cheques_dispo="cheques_dispo"
              :key="krm"
              @close="new_remise = false"
              @change="refresh_cheques"
              :modale="false"
              v-if="new_remise || remise.id > 0"
            ></remiseform>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>

    <confirmdialog ref="confirm" />
  </div>
</template>

<script>
//const cursorselect = () => import("./CursorSelect.vue");
import JOURNAL_ACHAT_VENTE from "../graphql/Compta/JOURNAL_ACHAT_VENTE.gql";
import JOURNAL_BANQUE from "../graphql/Compta/JOURNAL_BANQUE.gql";
import EXERCICE_PERIODE from "../graphql/Compta/EXERCICE_PERIODE.gql";
import CLOTURE_MOIS from "../graphql/Compta/CLOTURE_MOIS.gql";
import REMISES from "../graphql/Compta/REMISES.gql";
import ALLREMISES from "../graphql/Compta/ALLREMISES.gql";
import ALLPAYE from "../graphql/Facture/ALLPAYE.gql";
import UPDATE_PAYE from "../graphql/Paiement/UPDATE_PAYE.gql";
import DELETE_REMISE from "../graphql/Compta/DELETE_REMISE.gql";

export default {
  components: {
    listitemspage: () => import("../components/ListItemsPages.vue"),
    remiseform: () => import("../components/RemiseForm.vue"),
    confirmdialog: () => import("./ConfirmDialog.vue"),
  },
  name: "journalform",
  props: {
    item: Object,
    items: Array,
    readonly: Boolean,
    showForm: Boolean,
  },
  data: () => ({
    tabs: 0,
    selitem: {},
    progress: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    journals: [
      { id: 1, label: "Ventes" },
      { id: 2, label: "Achats" },
      { id: 3, label: "Banques" },
    ],
    cheques_list: [],
    cheques_dispo: [],
    remiseheaders: [
      {
        text: "Ref.",
        value: "ref",
        enum: "REF",
        selected: true,
        totlib: true,
        width: "110px",
      },

      {
        text: "Date",
        value: "date_remise",
        enum: "DATE_REMISE",
        slot: "date",
        selected: true,
        width: "105px",
      },
      {
        text: "Agence",
        align: "text-left",
        value: "agence",
        enum: "AGENCE",
        selected: true,
      },
      {
        text: "Compte",
        align: "text-left",
        value: "compte",
        enum: "COMPTE",
        selected: false,
      },
      {
        text: "Nb. Cheques",
        value: "nb_cheques",
        enum: "NB_CHEQUES",
        width: "50",
        selected: true,
      },
      {
        text: "Montant",
        value: "montant",
        slot: "cur",
        enum: "MONTANT",
        align: "end",
        width: "130",
        selected: true,
      },
      {
        text: "Statut",
        align: "text-left",
        value: "status_name",
        enum: "AGENCE",
        selected: true,
      },
    ],
    cheques_headers: [
      {
        text: "Code",
        value: "nopaye",
        selected: true,
        totlib: true,
        width: "120",
        index: 0,
      },

      {
        text: "Date Operation",
        value: "paye_date",
        slot: "date",
        selected: false,
        width: "110",
        index: 2,
      },
      {
        text: "Code Tier",
        align: "text-left",
        value: "tier_code",
        selected: false,
        index: 3,
      },
      {
        text: "Tier",
        align: "text-left",
        value: "tier_name",
        selected: true,
        index: 4,
      },
      {
        text: "Auxiliaire",
        align: "text-left",
        value: "auxiliaire",
        selected: false,
        index: 5,
      },
      {
        text: "Mode de Paiement",
        value: "type_paye",
        selected: false,
        index: 6,
      },
      {
        text: "Banque",
        value: "bank",
        list: [],
        selected: true,
        index: 7,
      },
      {
        text: "Ref.",
        value: "nocheque",
        selected: true,
        index: 8,
      },
      {
        text: "Date Cheque",
        value: "date_cheque",
        enum: "DATE_CHEQUE",
        slot: "date",
        selected: true,
        width: "90",
        index: 9,
      },

      {
        text: "Montant ",
        value: "montant",
        slot: "cur",
        selected: true,
        align: "end",
        total: "montant",
        width: "150",
        index: 10,
      },

      {
        text: "% Lettre ",
        value: "prc_lettre",
        slot: "progress",
        selected: false,
        align: "end",
        width: "100",
        index: 13,
      },

      {
        text: "Statut",
        value: "status_name",
        align: "text-center",
        selected: true,
        index: 16,
      },
      {
        text: "Rapprochement",
        value: "status_bank",
        align: "text-center",
        selected: true,
        width: "110",
        index: 17,
      },
      {
        text: "Date Rappr.",
        value: "date_effet",
        enum: "DATE_EFFET",
        slot: "date",
        selected: false,
        width: "110",
        index: 18,
      },
    ],
    new_remise: false,
    kq: 100,
    klist2: 250,
    klist3: 450,
    krm: 350,

    defaultItem: {},
    editedItem: {},
    periodes: [],
    journal_id: null,
    mois_id: null,
    exercice: null,
    periode_load: false,
    exercices: [],
    agences: [],
    periode: {},
    remise: {},
  }),

  computed: {
    Qselect() {
      return REMISES;
    },
    qDelete() {
      return DELETE_REMISE;
    },
    Qselectall() {
      return ALLREMISES;
    },
  },
  watch: {},

  created() {},
  async mounted() {
    this.exercice = this.$store.state.exercice;
    this.exercices.push(this.exercice);
    this.exercices.push(this.exercice - 1);
    this.periodes_load();
    this.refresh_cheques();
  },

  methods: {
    async refresh_cheques(item) {
      this.selitem = item;
      this.new_remise = false;
      let v = {
        where: {
          AND: [
            { column: "REM_ID", operator: "IS_NULL" },
            { column: "MODEPAIEMENT", value: 1 },
            { column: "STATUT_ID", value: 17 },
          ],
        },
        VarScope: 0,
        TypeScope: 3,
      };
      let r = await this.requette(ALLPAYE, v, "no-cache");

      if (r) this.cheques_dispo = r.allpaye;
    },
    RemiseChange(item) {
      this.remise = item;
      this.selitem = item;
      if (item.cheques) this.cheques_list = item.cheques;
      this.krm++;
    },
    RemiseOpen(item) {
      this.new_remise = item.id < 0;
      this.remise = item;
      this.selitem = item;
      if (item.cheques) this.cheques_list = item.cheques;
      this.krm++;
    },

    async DelCheque(item) {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir supprimer ce cheque?"
        )
      ) {
        let r = this.maj(UPDATE_PAYE, { paye: { id: item.id, rem_id: null } });
        if (r) {
          this.$store.dispatch("Changed", true);
          this.refresh_cheques();
        }
      }
    },
    async periodes_load() {
      this.periodes = [];
      this.periode_load = true;
      let r = await this.requette(EXERCICE_PERIODE, {
        exercice: this.exercice,
      });
      this.periode_load = false;
      if (r) {
        this.periodes = r.exercicesperiodes;
        this.agences = r.agences;
      }
    },
    periode_change() {
      let i = this.periodes.findIndex((elm) => elm.periode == this.mois_id);
      if (i >= 0) this.periode = this.periodes[i];
    },
    async requette(query, v, f) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: f,
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async get_journal(journal) {
      let ok = true;
      let list = [];
      if (journal == "achat" || journal == "vente") {
        let r = await this.requette(JOURNAL_ACHAT_VENTE, {
          fact_type: journal == "achat" ? 1 : 3,
          year: this.exercice,
          month: this.mois_id,
        });

        if (r) {
          list = r.getjournalachatvente;
          if (list.length > 0) {
            let cmpt = list[0].compte;
            let i = list.findIndex(
              (elm) => elm.codetier == null && elm.compte == cmpt
            );
            if (i >= 0) {
              this.snackbar_text =
                "Manque code comptable du tier " +
                list[i].nom +
                " Code : " +
                list[i].code;
              this.snackbar_color = "error";
              this.snackbar_timeout = 5000;
              this.snackbar = true;
              ok = false;
            }
          } else {
            this.snackbar_text = "Pas de factures a exporter ";
            this.snackbar_color = "error";
            this.snackbar_timeout = 2000;
            this.snackbar = true;
          }
        }
      }
      if (journal == "banque") {
        let r = await this.requette(JOURNAL_BANQUE, {
          agence_id: null,
          year: this.exercice,
          month: this.mois_id,
        });

        if (r) {
          list = r.GetJournalBanque;
          if (list.length > 0) {
            let cmpt = list[0].compte;
            let i = list.findIndex(
              (elm) => elm.codetier == null && elm.compte == cmpt
            );
            if (i >= 0) {
              this.snackbar_text =
                "Manque code comptable du tier " +
                list[i].nom +
                " Code : " +
                list[i].code;
              this.snackbar_color = "error";
              this.snackbar_timeout = 5000;
              this.snackbar = true;
              ok = false;
            }
          } else {
            this.snackbar_text = "Pas de journals a exporter ";
            this.snackbar_color = "error";
            this.snackbar_timeout = 2000;
            this.snackbar = true;
          }
        }
      }
      return ok ? list : [];
    },
    async lock_month() {
      let ok = true;
      if (this.periode.cloture == 0) {
        let l = await this.get_journal("vente");
        if (l.length == 0) ok = false;
      }
      if (ok) {
        this.is_motif = this.periode.cloture == 1;
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir " +
              (this.periode.cloture == 1 ? "dé" : "") +
              "cloturer le mois de " +
              this.periode.label +
              " " +
              this.exercice +
              "?"
          )
        ) {
          let r = await this.maj(CLOTURE_MOIS, {
            id: this.periode.id,
            cloture: this.periode.cloture == 0 ? 1 : 0,
            comment: this.motif,
          });
          if (r) {
            this.periode.cloture = this.periode.cloture == 0 ? 1 : 0;
            this.snackbar_text =
              "Mois " +
              (this.periode.cloture == 0 ? "dé" : "") +
              "cloturé avec succes";
            this.snackbar_color = "success";
            this.snackbar = true;
            this.periodes_load();
          } else {
            this.snackbar_text = "Erreur";
            this.snackbar_color = "error";
            this.snackbar = true;
          }
        }
      }
    },
    close() {
      this.$emit("close", this.items);
    },

    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },
    async maj(query, v) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    verifie_double() {
      let ok = true;
      let code = "";
      for (let index = 0; index < this.items.length; index++) {
        const element = this.items[index];
        if (this.editedItem.id > 0) {
          if (element.ref) {
            let l = this.items.filter((elm) => elm.id != element.id);
            for (let index = 0; index < l.length; index++) {
              const elm = l[index];
              if (elm.ref && element.ref)
                if (elm.ref.toLowerCase() == element.ref.toLowerCase()) {
                  code = element.ref;
                  ok = false;
                  break;
                }
            }
          }
        } else {
          if (this.editedItem.ref)
            if (
              this.editedItem.ref.toLowerCase() == element.ref.toLowerCase()
            ) {
              code = element.ref;
              ok = false;
            }
        }

        if (ok == false) break;
      }

      if (ok == false) {
        this.snackbar_text = "Erreur code " + code + " en double! verifier";
        this.snackbar_color = "error";
        this.snackbar = true;
        ok = false;
      }
      return ok;
    },
    async export_journal(journal) {
      this.downloadLoading = true;
      this.snackbar_text = "Chargement données";
      this.snackbar_color = "primary";
      this.snackbar = true;

      let headers = [
        "FOLIO",
        "PIECE",
        "DATE",
        "REFERENCE",
        "LIBELLE",
        "CODE_JRN",
        "CODE_COM",
        "CODE_AUX",
        "DEBIT",
        "CREDIT",
      ];
      let d = [
        "folio",
        "nopiece",
        "date",
        "ref",
        "libelle",
        "journal_code",
        "compte",
        "codetier",
        "debit",
        "credit",
      ];
      let l = await this.get_journal(journal);
      if (l.length > 0) {
        // let d = [];
        // l.forEach((element) => {
        //   d.push({
        //     journal_code: element.journal_code,
        //     folio: element.folio,
        //     nopiece: element.nopiece,
        //     compte: element.compte,
        //     codetier: element.codetier,
        //     ref: element.ref,
        //     libelle: element.libelle,
        //     debit: element.debit,
        //     credit: element.credit,
        //   });
        // });
        // exportToXLSX(d, "Journal.xlsx", headers, [7, 8]);
        import("@/utils/export").then((excel) => {
          const tHeader = headers;
          const filterVal = d;
          const data = this.formatJson(filterVal, l);
          excel.export_json_to_excel({
            header: tHeader,
            data,
            filename: "Journal",
            autoWidth: true,
            bookType: "xlsx",
          });
        });
        this.downloadLoading = false;
        this.snackbar_text = "Chargement terminé";
        this.snackbar_color = "success";
        this.snackbar = true;
      }
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
  },
};
</script>
